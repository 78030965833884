export default function Home() {
  return (
    <>
      <div
        style={{ width: '90%' }}
        className='max-w-screen-xl mt-4 text-sm md:text-base text-darkGray mx-auto '
      >
        <div className='mt-10 md:mt-0 flex-col md:flex-row flex md:space-x-4 justify-between items-center'>
          <div className='flex pr-7 base:pr-0 order-2 md:order-1 mt-8 md:mt-0 w-full justify-center md:w-1/2'>
            <img src='/banner.png' alt='' />
          </div>
          <div className='md:max-w-lg order-1 md:order-2 md:w-1/2'>
            <h1 className='text-black !leading-tight text-4xl  sm:text-5xl lg:text-6xl font-bold '>
              Get swift loans with ExpressOne!
            </h1>
            <p className='mb-8 mt-4'>
              With benefits ranging from low interest rates to quick disbursements, ExpressOne
              provides swift access to short-term loans the moment you need it!
            </p>
            <button className='bg-primary w-36 rounded-2xl text-white py-3'>Get Started</button>
          </div>
        </div>
        <div className='mt-12'>
          <h1 className='text-black  !leading-tight  text-4xl  sm:text-5xl font-bold '>
            Enjoy Express Deals!
          </h1>
          <p className='mt-2 max-w-md'>
            Get a loan in minutes with just a few clicks on your smartphone.
          </p>
        </div>
        <div className='mt-10 md:mt-0 py-24 md:flex  items-center'>
          <div className='flex w-full justify-center md:w-1/2'>
            <img className='max-w-sm' src='/phone.png' alt='' />
          </div>
          <div className='w-full pl-10 max-w-md md:w-1/2'>
            {cards.map((card) => (
              <Card {...card} />
            ))}
          </div>
        </div>
      </div>
      <div className='bg-bgLight overflow-hidden relative'>
        <div
          style={{ width: '90%' }}
          className='max-w-screen-xl relative z-10 py-32 mt-4 text-sm md:text-base  mx-auto '
        >
          <h2 className='text-center max-w-md mx-auto text-3xl  sm:text-3xl font-bold'>
            Short-Term and Personal Loans, No Collateral Needed!
          </h2>
          <p className='text-center text-darkGray mt-9 max-w-lg mx-auto'>
            Our loans range from N5,000 to N200,000 and with this, you can trust us to have your
            back during times of emergency.
          </p>
          <div className='relative grid sm:grid-cols-2 md:grid-cols-3 gap-6 lg:gap-10 mt-20'>
            {options.map((option) => (
              <Option option={option} />
            ))}
          </div>
        </div>
        <div className='parallelogram'></div>
      </div>
      <div className='bg-black'>
        <div id='download' className='mx-auto pt-20 pb-32 text-white' style={{ width: '90%' }}>
          <h1 className=' mx-auto max-w-3xl text-center !leading-tight  text-4xl  sm:text-5xl font-bold '>
            Let ExpressOne make you financially free within minutes!
          </h1>
          <p style={{ color: '#B3B3B3' }} className='mt-4 mx-auto text-center max-w-xl'>
            “With just a few taps from the comfort of your home, you can get access to loans ranging
            from N5,000 to N200,000 just like that. Yes, it’s that easy. We are dedicated to
            providing financial support to Nigerians who truly need it.” Springberry Capital Consult
            Ltd.
          </p>
          <div className='flex justify-center mt-12 space-x-4'>
            <a href='/'>
              <img src='/icons/google.svg' alt='' />
            </a>
            <a href='/'>
              <img src='/icons/apple.svg' alt='' />
            </a>
            <a href='/'>
              <img src='/icons/web.svg' alt='' />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

const Option = ({ option }) => (
  <div className='flex max-w-xs'>
    <div className='w-10'>
      <svg
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='4.61548'
          y='4.61548'
          width='25.3845'
          height='25.3845'
          rx='4.61537'
          fill='#FFCCCC'
        />
        <rect width='25.3845' height='25.3845' rx='4.61537' fill='#FF5D5B' />
      </svg>
    </div>
    <p className='ml-3 text-lg text-darkGray font-semibold'>{option}</p>
  </div>
)

const options = [
  'No bulky forms or collaterals needed!',
  'Low interest rates in order to make loans available to you the moment you need them.',
  'Get access to higher loan amounts when you repay loans in good time.',
]

const Card = ({ title, description }) => (
  <div className='border-b mt-10 py-2 border-light'>
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect x='4.61548' y='4.61548' width='25.3845' height='25.3845' rx='4.61537' fill='#FFCCCC' />
      <rect width='25.3845' height='25.3845' rx='4.61537' fill='#FF5D5B' />
    </svg>

    <h3 className='text-xl font-semibold mb-3 mt-1'>{title}</h3>
    <p>{description}</p>
  </div>
)

const cards = [
  { title: 'Easy', description: 'there’s absolutely no stress here!' },
  { title: 'Express', description: 'we disburse your loan in minutes!' },
  { title: 'Exclusive', description: 'we’re a safe space for your personal information!' },
  { title: 'Exceptional', description: 'we offer amazing deals and excellent services.' },
]
