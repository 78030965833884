import { Routes, Route } from 'react-router-dom'
import Home from './pages/index'
import Faq from './pages/faq'
import Privacy from './pages/privacy'
import Layout from './components/Layout'

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='faq' element={<Faq />} />
        <Route path='privacy' element={<Privacy />} />
      </Route>
    </Routes>
  )
}

export default App
