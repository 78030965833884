import React from 'react'
import { Element } from 'react-scroll'

const License = () => {
  return (
    <Element className='pt-24' id='license' name='license'>
      <h2 className='text-xl font-bold text-black'>End User License Agreement</h2>

      <div className='mt-2 space-y-4 leading-relaxed '>
        <p>
          The herein contained End User License Agreement ("Agreement") should be read carefully. It
          contains important terms that affect you and your use of the Software. By clicking "I
          accept" or by installing, copying, or using the Software, you agree to be bound by the
          terms of this Agreement, including the disclaimers. If you do not agree to these terms, do
          not download, install, copy, or use the Software.
        </p>
        <h2 className='font-bold text-black text-xl'>Software License</h2>
        <p>
          In this Agreement, "Software" means the ExpressOne Mobile Application which may include
          related printed material, media and any other components and/or software modules,
          including but not limited to required drivers. It could also include any necessary updates
          and upgrades.
        </p>
        <h2 className='font-bold text-black text-xl'>Grant of License.</h2>
        <p>
          By downloading via Google Play Store or Apple Store, installing, copying or using the
          Software, we grant you a limited, non-transferable, non-exclusive, royalty-free license to
          install and use the Software in accordance with the terms of this Agreement. This
          Agreement shall also govern any and all updates and upgrades that would replace, overwrite
          and/or supplement the original installed version of the Software unless those other
          upgrades are covered under a separate license, at which those terms of that license will
          govern.
        </p>
        <h2 className='font-bold text-black text-xl'>Terms of License</h2>

        <p>Permitted Uses:</p>
        <p>
          You may install and use the Software solely for your personal and non- commercial use. A
          commercial profit-oriented business is strictly prohibited.
        </p>
        <p>Restricted Uses:</p>
        <ol className=' list-decimal   px-5'>
          <li>
            <strong>No Distribution, etc.:</strong> You may not distribute, sub-license, loan, or
            sell the Software or any of its content that is contained or displayed in it to any
            third party.
          </li>
          <li>
            <strong>No Modification:</strong> You may not modify, alter, or create any derivative
            works of the Software, without the prior written consent of the company.
          </li>
          <li>
            <strong>No Reverse Engineering:</strong> You may not reverse, engineer, decompile,
            decode, decrypt, disassemble, or derive any source code from or about the Software.
          </li>
          <li>
            <strong>Proprietary Notices:</strong> You may not remove, alter, or obscure any
            copyright, trademark, or other proprietary rights notice on or in the Software.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Support and Maintenance</h2>
        <p>We will provide you with the necessary support and maintenance services as follows:</p>
        <ol className=' list-decimal   px-5'>
          <li>
            <strong>Email Support:</strong> During our normal business hours, we will attempt to
            respond to technical questions that you email to us within 24hours under the conducive
            atmosphere of operation.
          </li>
          <li>
            <strong>Patches and Fixes:</strong> We will attempt to correct issues identified in the
            Software by providing patches and fixes.
          </li>
          <li>
            <strong>Upgrade:</strong> Where applicable, we will attempt to distribute Software
            upgrades on a regular basis.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Updates</h2>
        <p>
          We may find the need from time to time to make available to all license holders updates
          for the offered content, in accordance with the herein contained terms and conditions of
          this Agreement. It shall be at our sole discretion to make conditional releases of said
          upgrade to you upon your acceptance of another end user license agreement. Should you
          elect to install and make use of these updates, you are therefore agreeing to be subject
          to all applicable license, terms and conditions of this Agreement and/or any other
          agreement.
        </p>
        <h2 className='font-bold text-black text-xl'>Information</h2>
        <p>
          You hereby authorize us to lawfully access, use or disclose your details processed on the
          Software for our use. This use may include but shall not be limited to such purposes as
          are necessary to promote or sustain the Software or its promotion. You hereby
          unequivocally waive any claim you may have against the company arising from any such
          access, use or disclosure.
        </p>
        <h2 className='font-bold text-black text-xl'>Term</h2>
        <p>
          This Agreement is effective upon acceptance and installation of the Software (the
          "Effective Date") and will continue until terminated in accordance with the terms of this
          agreement.
        </p>
        <h2 className='font-bold text-black text-xl'>Personal Data</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            Personal data is all information that directly or indirectly may refer to the Software
            User which he/she has provided in the course of this Agreement or use of the Software.
            Such includes Contact information [name, title, address, email address, mobile phone
            number etc., date of birth, employment information [name, address, phone number and
            email address of employee], financial information [information about the User’s income
            and any charges, where available.
          </li>
          <li>
            We, where necessary, will process the personal data in order to provide a simplified
            service and in order to simplify the process. We will process the personal data for
            confirming your identity, administering the customer relationship, preventing misuse or
            improper use of our Software, for the prevention of money laundering, customer analysis,
            assessment, risk analysis and management, business development, for commercial and
            marketing purposes and for complying with applicable laws.
          </li>
          <li>
            By accepting the Terms of this Software, you agree that we may use and/or reuse data
            provided or previously provided and we may carry out enquiries to help on your use of
            the Software. Where available, we will use such information to confirm your identity and
            simplify the use of our service.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Information Sharing and Disclosure</h2>
        <p>
          We will not share your Personal Data with anyone. However, we may share such information
          to third parties only on "Need basis" such as when: a. We have your consent to share the
          information b. The law requires it of us c. We respond to court orders or legal processes;
          or d. We find that your actions violate any part of the terms of the use of the Software.
        </p>
        <h2 className='font-bold text-black text-xl'>Warranties</h2>
        <ol className=' list-decimal   px-5'>
          <li>
            This Software and its content, with all its faults, defects, and errors, are provided
            "As Is" and without any warranty.
          </li>
          <li>
            We do not promise that the Software will be free of bugs, errors, viruses, or other
            defects. We will not be liable for your use of or inability to use the Software, its
            content, or any associated service. Disclaimer of All Other Warranties: We are not
            making any other express or implied warranties, including any implied warranties of
            title, quiet enjoyment, quiet possession, merchantability or fitness for a particular
            purpose, except as stated. The entire risk as to the quality of or performance of the
            Software, if any, rests with you.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Acknowledgements</h2>

        <ol className=' list-decimal   px-5'>
          <li>
            <strong>Ownership of Software:</strong> We own the Software and all intellectual
            property rights in and to it
          </li>
          <li>
            <strong>Third-Party Software:</strong> The Software may now or later contain third party
            software that requires notices or additional terms. If so, those notices and additional
            terms are and will be located on our website and are incorporated by reference into this
            Agreement.
          </li>
          <li>
            <strong>Consent to Use of Data:</strong> You agree that we and our affiliates may
            collect and use technical information gathered as part of our support services. We may
            use this information, but only to improve our products and services. We will not
            disclose this information in a form that personally identifies you.
          </li>
          <li>
            <strong>Compliance with Laws:</strong> In the use of the Software, you must comply with
            all applicable laws and regulations (including those relating to the import and export
            of software).
          </li>
          <li>
            <strong>Limitation of Liability:</strong> We will not be liable for breach-of-contract
            or damages that could not reasonably have been foreseen on entering into this Agreement.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Termination</h2>
        <ol className=' list-decimal   px-5'>
          <li>
            Termination by Us: We may terminate this Agreement upon five (5) days' prior written
            notice to you. Upon notice of such termination, the installation shall be disconnected
            or disabled. We shall also have the power to summarily terminate this Agreement devoid
            of any notice.
          </li>
          <li>Termination by You: You may terminate this Agreement by:</li>
          <ol className=' list-decimal   px-5'>
            <li>
              permanently destroying all copies of the Software in your possession or control,
            </li>
            <li>removing the Software from any hard drive on which it is installed, and</li>
            <li>giving us Notice certifying that you have taken these steps.</li>
          </ol>
        </ol>
        <h2 className='font-bold text-black text-xl'>General</h2>
        <ol className=' list-decimal   px-5'>
          <li>
            Entire Agreement: This Agreement contains all the terms between the Parties relating to
            its subject matter. It replaces all previous discussions, understandings, and
            agreements.
          </li>
          <li>
            Amendment: We may amend this Agreement on one or more occasions by posting amendments to
            our website. If you do not accept amendments to this agreement, then this license will
            immediately terminate.
          </li>
          <li>
            Assignment: You may not assign this Agreement or any of your rights under it. We may
            assign this Agreement or any of our rights at any time without notice or the need for
            your consent.
          </li>
          <li>
            Severability: If any part of this Agreement is declared unenforceable or invalid, the
            remainder will continue to be valid and enforceable.
          </li>
          <li>
            Waiver: A party's failure or neglect to enforce any rights under this Agreement will not
            be deemed to be a waiver of that party's rights.
          </li>
          <li>
            Notices: We may deliver any notice required by this Agreement via pop-up window, dialog
            box, or other on-screen device, even though you may not receive the notice until you
            next launch the Software. Any such notice will be deemed delivered on the date we first
            make it available through the Software.
          </li>
          <li>
            Governing Law: This Agreement will be governed and construed in accordance with the laws
            of the Federal Republic of Nigeria, without regard to its conflict of laws rules.
          </li>
          <li>
            Headings: The headings used in this Agreement and its division into sections, schedules,
            and other subdivisions do not affect its interpretation.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Contact Us</h2>
        <p>
          If you have any questions about this Agreement, please contact us by sending an email to{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>
        </p>
      </div>
    </Element>
  )
}

export default License
