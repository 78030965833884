import { Link } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline'
import { Fragment, useState } from 'react'
import FreshChat from 'react-freshchat'
import { Outlet } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

const navigation = [
  { name: 'Home', href: '/', current: false },
  { name: 'Contact', href: '#contact', current: false },
  { name: 'Faq', href: '/faq', current: false },
  { name: 'Download', href: '#', current: true },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div id='top'>
      <div
        style={{ width: '90%', display: 'unset' }}
        className='max-w-screen-xl z-50  relative text-sm md:text-base text-darkGray mx-auto '
      >
        <FreshChat token='e25ed369-d83b-4527-b69e-9905871555f4' />

        <div style={{ zIndex: 1000 }} className='sticky top-0 bg-white'>
          <header
            style={{ width: '90%' }}
            className='flex  py-5 z-40 mx-auto  max-w-screen-xl justify-between items-center'
          >
            <HashLink to='/#top'>
              <img className='w-52' src='/logo.png' alt='' />
            </HashLink>
            <nav className='hidden md:flex space-x-16 items-center text-sm md:text-base'>
              <Link to='/'>Home</Link>
              <HashLink to='#contact'>Contact</HashLink>
              <Link to='/faq'>Faq</Link>

              <HashLink to='/#download'>
                <button className='bg-primary w-36 rounded-2xl text-white py-3'>Download</button>
              </HashLink>
            </nav>
            <button
              type='button'
              className=' text-gray-500 focus:outline-none md:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuAlt2Icon className='h-8 w-8' aria-hidden='true' />
            </button>
          </header>
        </div>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 flex z-40 md:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-x-full'
            enterTo='translate-x-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-x-0'
            leaveTo='-translate-x-full'
          >
            <div className='relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white'>
              <Transition.Child
                as={Fragment}
                enter='ease-in-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in-out duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='absolute top-0 right-0 -mr-12 pt-2'>
                  <button
                    type='button'
                    className='ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XIcon className='h-6 w-6 text-white' aria-hidden='true' />
                  </button>
                </div>
              </Transition.Child>
              <div className='flex-shrink-0 flex items-center px-4'>
                <a href='/'>
                  <img className='w-52' src='/logo.png' alt='' />
                </a>
              </div>
              <div className='mt-5 flex-1 h-0 overflow-y-auto'>
                <nav className='px-2 mt-3 space-y-2'>
                  {navigation.map((item) => (
                    <HashLink
                      onClick={() => setSidebarOpen(false)}
                      className={classNames(
                        item.current ? 'bg-primary text-white' : 'text-darkGray hover:bg-light',
                        'group flex  items-center px-4 py-2 text-base font-medium rounded-md'
                      )}
                      to={item.href}
                    >
                      {item.name}
                    </HashLink>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className='flex-shrink-0 w-14' aria-hidden='true' />
        </Dialog>
      </Transition.Root>
      <Outlet />
      <div id='contact' style={{ borderTop: '1px solid #EDF2F7' }}>
        <footer
          style={{ width: '90%' }}
          className='pt-16 pb-16 md:pb-28 grid mx-auto max-w-screen-xl text-darkGray  sm:grid-cols-2 gap-6 lg:gap-10 '
        >
          <div>
            <HashLink to='/#top'>
              <img className='w-52' src='/logo.png' alt='' />
            </HashLink>
            <p className='mt-4 max-w-sm'>
              Low interest rates in order to make loans available to you the moment you need them.
            </p>
          </div>
          <div className='grid  sm:grid-cols-2 gap-6 lg:gap-10 '>
            <div className='flex text-sm flex-col space-y-4'>
              <h6 className='font-semibold text-lg'> Company</h6>
              <HashLink to='/privacy#privacy'>Privacy Policy</HashLink>
              <HashLink to='/privacy#terms'>Term of Use</HashLink>
              <HashLink to='/privacy#license'>License</HashLink>
              <HashLink to='/privacy#loan'>Loan</HashLink>
            </div>
            <div className='flex text-sm flex-col space-y-4'>
              <h6 className='font-semibold text-lg'> Address</h6>
              <p>19A Tejuosho Road, Yaba, Lagos</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Layout
