import React from 'react'
import Collapse, { Panel } from 'rc-collapse'
import 'rc-collapse/assets/index.css'

const faq = () => {
  return (
    <div
      style={{ width: '90%' }}
      className='max-w-screen-lg my-12 sm:my-16  text-sm md:text-base text-darkGray mx-auto '
    >
      <div className='text-center py-12 sm:py-16'>
        <h1 className='md:text-5xl sm:text-5xl text-3xl font-bold text-black'>
          Frequently Asked Questions (FAQs)
        </h1>
        <p className='my-8'>Don't worry, we've got you!</p>
      </div>
      <div className='sm:flex sm:space-x-28'>
        <h1 className='mb-10 sm:w-1/12 sm:mb-0 text-center sm:text-left text-primary font-bold text-3xl'>
          General
        </h1>
        <Collapse
          openMotion={collapseMotion}
          expandIcon={expandIcon}
          className=' flex-1 text-darkGray !bg-white !border-none space-y-4'
          accordion={true}
        >
          {General.map((item) => (
            <Panel
              header={item.title}
              className='!text-darkGray'
              headerClass='font-bold !leading-tight text-xl sm:text-2xl !py-4 !text-darkGray'
            >
              {item.desc}
            </Panel>
          ))}
        </Collapse>
      </div>
      <div className='sm:flex my-20 sm:space-x-28'>
        <h1 className='mb-10  sm:w-1/12 sm:mb-0 text-center sm:text-left text-primary font-bold text-3xl'>
          Loans
        </h1>
        <Collapse
          openMotion={collapseMotion}
          expandIcon={expandIcon}
          className=' flex-1 text-darkGray !bg-white !border-none space-y-4'
          accordion={true}
        >
          {Loans.map((item) => (
            <Panel
              header={item.title}
              className='!text-darkGray'
              headerClass='font-bold !leading-tight text-xl sm:text-2xl !py-4 !text-darkGray'
            >
              {item.desc}
            </Panel>
          ))}
        </Collapse>
      </div>
      <div className='sm:flex sm:space-x-28'>
        <h1 className='mb-10 sm:w-1/12 sm:mb-0 text-center sm:text-left text-primary font-bold text-3xl'>
          Security
        </h1>
        <Collapse
          openMotion={collapseMotion}
          expandIcon={expandIcon}
          className=' flex-1 text-darkGray !bg-white !border-none space-y-4'
          accordion={true}
        >
          {Security.map((item) => (
            <Panel
              header={item.title}
              className='!text-darkGray'
              headerClass='font-bold !leading-tight text-xl sm:text-2xl !py-4 !text-darkGray'
            >
              {item.desc}
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  )
}

export default faq

function expandIcon({ isActive }) {
  return (
    <i className='mr-4'>
      {isActive ? (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='1.494' viewBox='0 0 12 1.494'>
          <path
            data-name='_ionicons_svg_ios-remove (4)'
            d='M138.753,240H128.247a.747.747,0,0,0,0,1.494h10.506a.747.747,0,1,0,0-1.494Z'
            transform='translate(-127.5 -240)'
            fill='currentColor'
          />
        </svg>
      ) : (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'>
          <path
            data-name='_ionicons_svg_ios-add (7)'
            d='M138.753,132.753h-4.506v-4.506a.747.747,0,1,0-1.494,0v4.506h-4.506a.747.747,0,0,0,0,1.494h4.506v4.506a.747.747,0,1,0,1.494,0v-4.506h4.506a.747.747,0,1,0,0-1.494Z'
            transform='translate(-127.5 -127.5)'
            fill='currentColor'
          />
        </svg>
      )}
    </i>
  )
}

const General = [
  {
    title: 'What is ExpressOne?',
    desc: 'ExpressOne is a lending app designed with the intention of making the lending process easy. The application grants you easy access to loans with just a few clicks.',
  },
  {
    title: 'How do I get started?',
    desc: 'Our app is easily accessible on Google Store and App Store for download and you can get started in just a few minutes and most importantly, it is completely free.',
  },
  {
    title: 'Is ExpressOne registered',
    desc: 'Yes, ExpressOne is a product of Springberry Capital Consult Ltd with 1847895.',
  },
]

const Loans = [
  {
    title: 'How do I apply for a loan on ExpressOne?',
    desc: 'Applying for a loan is a very simple process on ExpressOne. Once you are logged into App, navigate to the Loan section and fill in your personal and employment details for a loan request.',
  },
  {
    title: 'How much can I borrow on ExpressOne?',
    desc: 'For your first loan request, you can borrow as much as N5,000. Your loan eligibility increases as we get to know you better.',
  },
  {
    title: 'What do I need to get a loan on ExpressOne?',
    desc: `We keep to our words, no documentation, no collaterals. All you need to make a loan request on ExpressOne are just:
  Your personal details.
  Your employment details.
  A functional debit card.
  `,
  },
  {
    title: 'How does ExpressOne make a lending decision?',
    desc: 'A lending decision is made from your credit score as a borrower. Your credit score is based on the information that you provide to us. The more genuine this information are, the higher your chances of getting a loan.  ',
  },
  {
    title: 'Why was my loan request declined?',
    desc: "We're very sorry for declining your request. It only means we don't have a loan product that matches your profile at the moment. We want you to check back with us soon.",
  },
  {
    title: 'Will my loan size increase as I repay?',
    desc: 'Yes, the more you repay the higher the loan amount you are eligible for.',
  },
  {
    title: 'How can I repay my loan?',
    desc: 'You can repay your loan either manually or via the auto-debit service.',
  },
  {
    title: 'How does auto-debit work?',
    desc: 'The auto-debit service makes loan repayment seamless. The service uses the debit card attached to your active loan to initiate a loan repayment for you. Auto-debit helps you stay true to your words and increase your credit score.',
  },
  {
    title: 'What cards can I auto-debit?',
    desc: "ExpressOne supports MasterCard, Visa, and Verve cards for auto-debit as long as there are sufficient funds in the account, you don't need to worry.",
  },
  {
    title: 'Can I repay early?',
    desc: "Sure! You can pay as soon as you can afford to repay, it doesn't matter if the loan is not due yet.",
  },
  {
    title: 'Can I pay down on my loan?',
    desc: 'Yes, you can pay in instalments to reduce your total outstanding loan.',
  },
]

const Security = [
  {
    title: 'Why do I have to add my BVN?',
    desc: 'Your BVN is strictly for identity verification to help us kick against identity theft and protect your funds and transactions.',
  },
  {
    title: 'Are my card details safe?',
    desc: 'Your security is our topmost priority. We warehouse your card details with a PCI-DSS compliant payment processor, Paystack for your security.',
  },
  {
    title: 'What should I do when I need help?',
    desc: 'The easiest way to reach us is to “Chat Support” under the Settings section of the app. You can also send an email to support@expressoneng.com  or call +2348086822588',
  },
]

const getCollapsedHeight = () => ({ height: 0, opacity: 0 })
const getRealHeight = (node) => ({ height: node.scrollHeight, opacity: 1 })
const getCurrentHeight = (node) => ({ height: node.offsetHeight })
const skipOpacityTransition = (_, event) => event.propertyName === 'height'

const collapseMotion = {
  motionName: 'rc-collapse-motion',
  onEnterStart: getCollapsedHeight,
  onEnterActive: getRealHeight,
  onLeaveStart: getCurrentHeight,
  onLeaveActive: getCollapsedHeight,
  onEnterEnd: skipOpacityTransition,
  onLeaveEnd: skipOpacityTransition,
  motionDeadline: 500,
  leavedClassName: 'rc-collapse-content-hidden',
}
