import React from 'react'
import { Link } from 'react-scroll'

const menu = [
  {
    name: 'Privacy Policy',
    id: 'privacy',
  },
  {
    name: 'Terms of use',
    id: 'terms',
  },
  { name: 'License', id: 'license' },
  { name: 'Loan', id: 'loan' },
]

export const Menu = () => {
  return (
    <div className='hidden sm:block sticky h-28 top-40 sm:w-3/12'>
      <div className='flex flex-col space-y-4'>
        {menu.map((item) => (
          <Link
            activeClass='active'
            to={item.id}
            spy={true}
            hashSpy
            isDynamic
            className='font-bold cursor-pointer'
            smooth={true}
            offset={-50}
            duration={500}
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  )
}
