import React from 'react'
import { Element } from 'react-scroll'

const Terms = () => {
  return (
    <Element className='pt-24' id='terms' name='terms'>
      <h2 className='text-xl font-bold text-black'>Terms of use</h2>

      <div className='mt-2 space-y-4 leading-relaxed '>
        <p>
          This Agreement is a financial services and an end-user license agreement between you
          (“End-user” “Borrower” or “you”) and the relevant Springberry Capital Consult Ltd entity
          duly incorporated and licensed under the laws of your country (“ExpressOne”, “us” or “we”)
          for the mobile application software, the data supplied with the software and the
          associated Services (defined below) (“App”).{' '}
        </p>
        <p>
          This Agreement (together with our Privacy Policy) sets out the complete terms and
          conditions (the “Terms and Conditions”) which shall be applicable to the Account (as
          hereinafter defined) opened by you with ExpressOne.
        </p>
        <p>
          These Terms and Conditions and any amendments or variations thereto take effect on their
          date of publication.
        </p>
        <h2 className='font-bold text-black'>IT IS AGREED AS FOLLOWS:</h2>
        <ol className=' list-decimal  px-5'>
          <li className='font-bold text-black'>DEFINITIONS AND INTERPRETATION</li>
          <li className='font-bold text-black'>Definitions</li>
        </ol>
        <p>
          For the purposes of this Agreement and the preamble above, unless the context requires
          otherwise:
        </p>
        <ul className=' list-disc px-5'>
          <li>
            <strong>Acceptable Use Restrictions</strong> has the meaning given to it in clause 5;
          </li>
          <li>
            <strong>Agreement</strong> means this Agreement;
          </li>
          <li>
            <strong>Account</strong> means your loan and savings account with ExpressOne;
          </li>
          <li>
            <strong>Business Day</strong> means a day other than a Saturday, Sunday or national or
            public holiday in your country;
          </li>
          <li>
            <strong>Credentials</strong> means your personal credentials used to access the App and
            operate your Account;
          </li>
          <li>
            <strong>Credit Reference Bureau </strong> includes any financial institution or a credit
            reference bureau duly licensed under the laws and regulations of your country at every
            given time, to inter alia, collect and facilitate the sharing of customer credit
            information;
          </li>
          <li>
            <strong>Electronic Money</strong> means a monetary value that is electronically stored
            in an instrument or device representing an equal amount of cash;
          </li>
          <li>
            <strong>Electronic Payment Service</strong> means the payment service delivered in
            electronic form by a licensed payment service provider in accordance with the laws and
            regulations of your Nigeria;
          </li>
          <li>
            <strong>Electronic Payment System</strong> means the facility operated by the Payment
            System Provider for the provision of the Electronic Payment Service;
          </li>
          <li>
            <strong>Encumbrance</strong> includes any mortgage or charge (whether legal or
            equitable), lien, option, security interest, restrictive covenant, pledge, assignment,
            title retention, trust arrangement or other restriction of any kind or other encumbrance
            securing or any right conferring a priority of payment in respect of any obligation of
            any person;
          </li>
          <li>
            <strong>Equipment</strong> includes your mobile phone handset, SIM Card and/or other
            equipment which when used together enables you to access the Network;
          </li>
          <li>
            <strong>Event of Default </strong> has the meaning given to it in clause 11.1;
          </li>
          <li>
            <strong>Force Majeure</strong> means events, circumstances or causes beyond the
            reasonable control of ExpressOne making ExpressOne’s performance of its obligations
            inadvisable, commercially impracticable, illegal, or impossible, including but not
            limited to acts of God, war, strikes or labour disputes, embargoes or government orders,
            pandemics, epidemics;
          </li>
          <li>
            <strong>Licence Restrictions</strong> has the meaning given to it in clause 4;
          </li>
          <li>
            <strong>Loan</strong> means the principal amount of the loan made or to be made by
            ExpressOne to you under this Agreement from time to time through the App or (as the
            context requires) the principal amount outstanding for the time being of that loan;
          </li>
          <li>
            <strong>Mobile Network Operator</strong> means a mobile network operator in your country
            licensed in accordance with the relevant laws and regulations;
          </li>
          <li>
            <strong>Network</strong> means a mobile cellular network operated by a Mobile Network
            Operator;
          </li>
          <li>
            <strong>Payment System Provider</strong> means a Mobile Network Operator, Deposit Banks,
            and other financial institutions that has been duly licensed by the laws and regulations
            of Nigeria to provide electronic payment services in Nigeria;
          </li>
          <li>
            <strong>Privacy Policy</strong> means the Nigerian Privacy Policy that sets out the
            basis on which any personal data we collect from you, or that you provide to us, will be
            processed by us;
          </li>
          <li>
            <strong>Request</strong> means a request or instruction received by ExpressOne from you
            or purportedly from you through the Network and the Electronic Payment System and upon
            which ExpressOne is authorised to act;
          </li>
          <li>
            <strong>Services</strong> shall include any form of financial services or products that
            ExpressOne may offer you pursuant to this Agreement and which you may from time to time
            subscribe to; and “Service” shall be construed accordingly;
          </li>
          <li>
            <strong>SIM Card</strong> means the subscriber identity module which when used with the
            appropriate mobile phone handset enables you to access the Network and, in some cases,
            to use the Value Storage Account (as defined below);
          </li>
          <li>
            <strong>SMS</strong> means a short message service consisting of a text message
            transmitted from your mobile phone to another;
          </li>
          <li>
            <strong>System</strong> means ExpressOne’s electronic communications software enabling
            you to communicate with ExpressOne for purposes of the Services. The System and the
            Services will for the purpose of this Agreement be accessed through the Electronic
            Payment System;
          </li>
          <li>
            <strong>Technology</strong> has the meaning given to it in clause 3.1.4;
          </li>
          <li>
            <strong>Transaction Fees</strong> includes any fees and charges payable for the use of
            the Services as published by ExpressOne on ExpressOne’s website or by such other means
            as ExpressOne shall in its sole discretion determine. Transaction Fees are subject to
            change at any time at ExpressOne’s sole discretion; and
          </li>
          <li>
            <strong>Value Storage Account</strong> means your electronic money store of value, being
            the record maintained by a Payment System Provider from time to time held by you in the
            Electronic Payment System.
          </li>
        </ul>
        <h2 className=' text-black font-bold'>1.2 Interpretation</h2>
        <p>In addition to the definitions in clause 1.1, unless the context requires otherwise: </p>
        <p>the singular shall include the plural and vice versa;</p>
        <p>
          a reference to any one gender, whether masculine, feminine or neuter, includes the other
          two.
        </p>
        <p>
          all the headings and sub-headings in this Agreement are for convenience only and are not
          to be taken into account for the purposes of interpretation of this Agreement.
        </p>
        <p>the recitals and schedules shall be deemed to form part of this Agreement.</p>
        <p> </p>
        <h2 className=' text-black font-bold text-xl'>2. ACCEPTANCE OF TERMS AND CONDITIONS</h2>
        <p>
          2.1 You must carefully read and understand the Terms and Conditions set out in this
          Agreement and as amended from time to time by ExpressOne (the Terms and Conditions) before
          downloading or streaming the App or opening an account with ExpressOne which will govern
          the use and operation of the App and the Account.
        </p>
        <p>
          2.2 After downloading the App, you will be deemed to have accepted the Terms and
          Conditions immediately upon your clicking the “Continue” button during your Loan
          application on ExpressOne’s System or any other button which signifies your acceptance of
          these Terms and Conditions and an intention to use the App. If you do not agree with the
          Terms and Conditions please do not click “Continue” in ExpressOne’s System. Please note
          that you will not be able to access the Services if you decline the Terms and Conditions.
          If you do not agree to the Terms and Conditions, we will not give you access to ExpressOne
          Services.
        </p>
        <p>
          {' '}
          2.3 By downloading the App and opening an Account with ExpressOne, you agree to comply
          with and be bound by the Terms and Conditions governing the operation of the Account and
          you affirm that the Terms and Conditions herein are without prejudice to any other right
          that ExpressOne may have with respect to the Account in law or otherwise.{' '}
        </p>
        <p>
          {' '}
          2.4 These Terms and Conditions may be amended or varied by ExpressOne from time to time
          and your continued use of the Services constitutes your agreement to be bound by the terms
          of any such amendment or variation. ExpressOne will take all reasonable measures to notify
          you of any changes.{' '}
        </p>
        <p>
          {' '}
          2.5 From time to time updates to the App may be issued. Depending on the update, you may
          not be able to use the Services until you have downloaded or streamed the latest version
          of the App and accepted any new terms and conditions.{' '}
        </p>
        <p>
          {' '}
          2.6 By using the App or any of the Services, you consent to us collecting and using
          technical information about the Equipment and related software, hardware and peripherals
          for Services that are internet-based or wireless to improve our products and to provide
          any Services to you. If you use these Services, you consent to us and our affiliates' and
          licensees' transmission, collection, retention, maintenance, processing and use of your
          data to determine our credit scoring services or to improve our Services and/or your
          experience while using the App.
        </p>

        <p>
          These terms and conditions ("Terms", "Agreement") is an agreement between ExpressOne, that
          is operated by ExpressOne Limited and you (the user). This Agreement sets forth the
          general terms and conditions of your use of any of its products or services (collectively,
          "Mobile Application" or "Services").
        </p>
        <h2 className=' text-black font-bold text-xl'>3. GRANT AND SCOPE OF LICENCE</h2>
        <p> </p>
        <p>
          3.1 In consideration of you agreeing to abide by the terms of this Agreement, we grant you
          a non-transferable, non-exclusive licence to use the App on your Equipment, subject to
          these Terms and Conditions. We reserve all other rights. Except as expressly set out in
          this Agreement or as permitted by any local law, you agree:
        </p>
        <p>
          3.1.1 not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the
          App;
        </p>
        <p>
          3.1.2 not to make alterations to, or modifications of, the whole or any part of the App,
          or permit the App or any part of it to be combined with, or become incorporated in, any
          other programs;
        </p>
        <p>
          3.1.3 not to disassemble, decompile, reverse-engineer or create derivative works based on
          the whole or any part of the App or attempt to do any such thing except to the extent that
          such actions cannot be prohibited because they are essential for the purpose of achieving
          inter-operability of the App with another software program, and provided that:{' '}
        </p>
        <p>
          3.1.3.1 the information obtained by you during such activities is not disclosed or
          communicated without our prior written consent to any third party, and is not used to
          create any software that is substantially similar to the App,
        </p>
        <p>
          3.1.3.2 you include our copyright notice on all entire and partial copies you make of the
          App on any medium and you undertake not to provide or otherwise make available the App in
          whole or in part (including object and source code), in any form to any person without
          prior written consent from us; and{' '}
        </p>
        <p>
          3.1.4 to comply with all technology control or export laws and regulations in your country
          that apply to the technology used or supported by the App or any Service (the Technology).
        </p>
        <p> </p>
        <h2 className=' text-black font-bold text-xl'>4. LICENCE RESTRICTIONS</h2>
        <p>4.1 You must not:</p>
        <p>
          4.1.1 use the App or any Service in any unlawful manner, for any unlawful purpose, or in
          any manner inconsistent with this Agreement, or act fraudulently or maliciously, for
          example, by hacking into or inserting malicious code, including viruses, or harmful data,
          into the App, any Service or any operating system;
        </p>
        <p>
          4.1.2 infringe our intellectual property rights or those of any third party in relation to
          your use of the App or any Service, including the submission of any material (to the
          extent that such use is not licensed by this Agreement);
        </p>
        <p>
          4.1.3 transmit any material that is defamatory, offensive or otherwise objectionable in
          relation to your use of the App or any Service;
        </p>
        <p>
          4.1.4 use the App or any Service in a way that could damage, disable, overburden, impair
          or compromise our systems or security or interfere with other users; and
        </p>
        <p>
          4.1.5 collect or harvest any information or data from any Service or our systems or
          attempt to decipher any transmissions to or from the servers running any Service.
        </p>
        <h2 className=' text-black font-bold text-xl'>5. ACCEPTABLE USE RESTRICTIONS</h2>
        <p> </p>
        <h2 className=' text-black font-bold'>5.1 Intellectual Property Rights</h2>
        <p>
          5.1.1 You acknowledge that all intellectual property rights in the App and the Technology
          anywhere in the world belong to us or our licensors, that rights in the App are licensed
          (not sold or assigned) to you, and that you have no rights in, or to, the App or the
          Technology other than the right to use each of them in accordance with the terms of this
          Agreement. You acknowledge that you have no right to have access to the App in source-code
          form.
        </p>
        <h2 className=' text-black font-bold'>5.2 Use of The Services</h2>
        <p>
          5.2.1 The Services offered by ExpressOne can only be utilized by persons over the age of
          18. ExpressOne reserves the right to verify the authenticity and status of your Bank
          Accounts and Bank Verification Numbers with the relevant Payment System Provider.
        </p>
        <p>
          5.2.2 ExpressOne’s acceptance of your application for an Account will be displayed on the
          App. You hereby acknowledge and accept that the acceptance by ExpressOne of your
          application for an Account does not create any contractual relationship between you and
          the Payment System Provider.
        </p>
        <p>
          5.2.3 ExpressOne reserves the right to decline your application for a Loan or to revoke
          the same at any stage at ExpressOne’s sole and absolute discretion and without assigning
          any reason or giving any notice thereto.
        </p>
        <p>
          5.2.4 ExpressOne reserves the right (in its sole and absolute discretion) to issue,
          decline to issue a Loan and/or vary the terms of any Loan depending on its assessment of
          the credit profile of each individual borrower from time to time. The terms of the Loan
          and the interest rate payable in relation to each loan application will be displayed on
          the App.
        </p>
        <p> </p>
        <h2 className=' text-black font-bold'>5.3 Personal Information</h2>
        <p>
          5.3.1 You hereby agree and authorise ExpressOne to verify information provided by you to
          ExpressOne against the information held by the Payment System Providers pursuant to the
          agreement between you and the relevant Payment System Provider for the provision of its
          products and services and the Electronic Payment Service and any other information bank
          available to ExpressOne in Nigeria.
        </p>
        <p>
          {' '}
          5.3.2 The information that ExpressOne may verify against the information held by the
          Payment System Providers includes (without limitation): your phone number, name, date of
          birth, Identification Number (“ID”) or Passport Number and such other information that
          will enable ExpressOne to identify you and comply with the regulatory “Know Your Customer”
          requirements in your country (together the “Personal Information”).
        </p>
        <p>
          5.3.3 You hereby agree and authorise ExpressOne to verify information including, but not
          limited to, data relating to your phone (including, without limitation, your phone’s
          history) from your Equipment, from any SMS sent to you or by you, from any 3rd party
          applications, and such other information as ExpressOne shall require for purposes of
          providing you the Services (the “Relevant Information”).
        </p>
        <p>
          {' '}
          5.3.4 You hereby consent to ExpressOne verifying the Personal Information and the Relevant
          Information with the Payment System Providers and using the Personal Information and the
          Relevant Information to the extent necessary in ExpressOne’s opinion.
        </p>
        <p>
          5.3.5 You hereby agree and authorise ExpressOne to obtain and procure your Personal
          Information and Relevant Information from your respective Payment System Provider and you
          further agree and consent to the disclosure and provision of such Personal Information by
          the Payment System Provider and further to indemnify and hold ExpressOne and the Payment
          System Provider harmless with respect to any claims, losses, liabilities and expenses
          (including legal fees and expenses) that may arise as a result of the disclosure and
          reliance on such Personal Information and/or Relevant Information.
        </p>
        <p>
          5.3.6 You hereby agree and authorise ExpressOne to obtain and procure your Personal
          Information from the Credit Reference Bureaus and you further agree and consent to the
          disclosure and provision of such Personal Information by the Credit Reference Bureaus.
        </p>
        <p>
          5.3.7 ExpressOne reserves the right to request for further information from you pertaining
          to your application for an Account at any time. Failure to provide such information within
          the time required by ExpressOne may result in ExpressOne declining to accept your
          application for an Account or a Loan.
        </p>
        <p>
          5.3.8 ExpressOne reserves the right to supply consumer credit information and information
          about the Borrower to the Credit Reference Bureaus, and in this regard: you confirm that
          ExpressOne may transmit to the Credit Reference Bureaus data about the App, opening and
          termination of an Account by you.{' '}
        </p>
        <p>
          5.3.9 You acknowledge that information on non-compliance with the Terms and Conditions of
          this Agreement may be transferred to the Credit Reference Bureaus; and
        </p>
        <p>
          5.3.10 The Credit Reference Bureaus may provide ExpressOne with a credit profile and
          possibly credit scores on your creditworthiness.
        </p>
        <p> </p>
        <h2 className=' text-black font-bold text-xl'>6. REQUESTS MADE BY THE BORROWER</h2>
        <p>
          6.1 You hereby irrevocably authorise ExpressOne to act on all Requests received by
          ExpressOne from you (or purportedly from you) through the System or your Equipment and to
          hold you liable in respect thereof. ExpressOne may nevertheless refuse to carry out any
          Requests in its sole and absolute discretion.
        </p>
        <p>
          6.2 Subject to its discretion, ExpressOne reserves the right to reject any Request in
          relation to a Loan application from you even if you have previously been issued with a
          Loan by ExpressOne.
        </p>
        <p>
          6.3 ExpressOne shall be entitled to accept and to act upon any Request, even if that
          Request is otherwise for any reason incomplete or ambiguous if, in its absolute
          discretion, ExpressOne believes that it can correct the incomplete or ambiguous
          information in the Request without any reference to you being necessary.
        </p>
        <p>
          6.4 ExpressOne shall be deemed to have acted properly and to have fully performed all the
          obligations owed to you notwithstanding that the Request may have been initiated, sent or
          otherwise communicated in error or fraudulently, and you shall be bound by any Requests on
          which ExpressOne may act if ExpressOne has in good faith acted in the belief that such
          instructions have been sent by you.
        </p>
        <p>
          6.5 ExpressOne may, in its absolute discretion, decline to act on or in accordance with
          the whole or any part of your Request pending further enquiry or further confirmation
          (whether written or otherwise) from you.
        </p>
        <p>
          6.6 You agree to and shall release from and indemnify ExpressOne against all claims,
          losses, damages, costs and expenses howsoever arising in consequence of, or in any way
          related to ExpressOne having acted in accordance with the whole or any part of any of your
          Requests (or failed to exercise) the discretion conferred upon it.
        </p>
        <p>
          6.7 You acknowledge that to the full extent permitted by law, ExpressOne shall not be
          liable for any unauthorised drawing, transfer, remittance, disclosure, any activity or any
          incident on your account by the fact of the knowledge and/or use or manipulation of your
          Account Personal Identification Number, password, ID or any means whether or not
          occasioned by your negligence.
        </p>
        <p>
          6.8 ExpressOne is authorised to effect such orders in respect of your Account as may be
          required by any court order or competent authority or agency under the applicable laws in
          your country.
        </p>
        <p>
          6.9 In the event of any conflict between any terms of any Request received by ExpressOne
          from you and this Agreement, this Agreement shall prevail.
        </p>
        <h2 className=' text-black font-bold text-xl'>7. INTEREST AND FEES</h2>
        <p>
          7.1 The interest payable by you to ExpressOne in relation to any Loan shall be displayed
          by ExpressOne on the App. ExpressOne shall be entitled to set and charge Transaction Fees,
          in connection with your use of the Services and from time to time amend or vary its
          Transaction Fees for the Services. If ExpressOne decides to charge Transaction Fees or
          where already applicable, vary or amend its Transaction Fees, the Transaction Fees payable
          on any new application for Services will be displayed on the App. ExpressOne will use
          reasonable endeavors to notify you of any changes in relation to Transaction Fees within a
          reasonable period before such changes are implemented including displaying notices of the
          changes on the App or ExpressOne’s website.
        </p>
        <p>
          7.2 All payments to be made by you under this Agreement shall be made in full without any
          set off or counterclaim and save in so far as required by the law to the contrary, free
          and clear of and without any deduction or withholding whatsoever. If you are at any time
          required to make any deduction or withholding from any payment to ExpressOne, you shall
          immediately pay to ExpressOne such additional amounts as will result in ExpressOne
          receiving the full amount it would have received had no such deduction or withholding been
          required.
        </p>
        <p>
          7.3 If you fail to make any payments due to ExpressOne at the due date for payment,
          ExpressOne will be authorised to apply late fees on such amount loaned to you at a rate to
          be communicated to you.
        </p>
        <h2 className=' text-black font-bold text-xl'>8. STATEMENTS</h2>
        <p>
          {' '}
          8.1 A statement and activity report in respect of your account will be made available on
          request. Requests shall be made via our e-mail address:{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>{' '}
          or via a contact link on the App.
        </p>
        <p>
          {' '}
          8.2 The statement on the App shall provide details of the last 4 (four) transactions (or
          such other number of transactions as determined by ExpressOne) in your Account initiated
          from your Equipment.
        </p>
        <p>
          8.3 Your statement will show all amounts added or taken from your Account. You must check
          your statement carefully and notify ExpressOne as soon as possible, and no later than 48
          hours after receipt of your statement, if it includes any transaction or other entry which
          appears to you to be wrong or not made in accordance with your instructions.
        </p>
        <p>
          8.4 ExpressOne reserves the right to rectify discrepancies, add and/or alter the entries
          in your statements, without prior notice to you.
        </p>
        <p>
          8.5 Save for a manifest error, a statement issued to you in respect of your Account shall
          be conclusive evidence of the transactions carried out on your ExpressOne for the period
          covered in the statement.
        </p>
        <h2 className=' text-black font-bold text-xl'>9. TAXES</h2>
        <p>
          9.1 All payments to be made by you in connection with these Terms and Conditions or any
          Loan provided to you by ExpressOne are calculated without regard to any taxes payable by
          you in your country. If any taxes are payable in connection with the payments, you must
          ensure that you pay such taxes and that you pay to ExpressOne such additional amounts as
          will result in ExpressOne receiving the full amount it would have received had no such
          taxes been applicable to the payments.
        </p>
        <p>
          {' '}
          9.2 You hereby consent and agree that ExpressOne may withhold amounts in your Account if
          any tax authority in your country requires ExpressOne to do so, or ExpressOne is otherwise
          required by law or pursuant to agreements with any tax authority to do so, or if
          ExpressOne needs to comply with internal policies or with any applicable order or sanction
          of a tax authority in your country. You will, however, be notified if such deductions are
          made.
        </p>
        <h2 className=' text-black font-bold text-xl'>10. BORROWER’S RESPONSIBILITIES</h2>
        <p>
          {' '}
          10.1 You shall at your own expense provide and maintain in safe and efficient operating
          order your Equipment necessary for the purpose of accessing the System and the Services.
        </p>
        <p>
          10.2 You shall be responsible for ensuring the proper performance of your Equipment.
          ExpressOne shall neither be responsible for any errors or failures caused by any
          malfunction of your Equipment, nor shall ExpressOne be responsible for any computer virus
          or related problems that may be associated with the use of the System, the Services and
          the Equipment. You shall be responsible for charges due to any service provider providing
          you with connection to the Network and ExpressOne shall not be responsible for losses or
          delays caused by any such service provider.
        </p>
        <p>
          10.3 You shall follow all instructions, procedures and terms contained in this Agreement
          and any document provided by ExpressOne concerning the use of the System and the Services.
        </p>
        <p>
          10.4 You hereby agree and acknowledge that you shall be solely responsible for the
          safekeeping and proper use of your Equipment and for keeping your Credentials secret and
          secure. You shall ensure that your Credentials do not become known or come into possession
          of any unauthorised person. ExpressOne shall not be liable for any disclosure of your
          Credentials to any third party and you hereby agree to indemnify and hold ExpressOne
          harmless from any losses resulting from any disclosure of your Credentials.
        </p>
        <p>
          10.5 You shall take all reasonable precautions to detect any unauthorised use of the
          System and the Services. To that end, you shall ensure that all communications from
          ExpressOne are examined and checked by you or on your behalf as soon as practicable after
          receipt by you in such a way that any unauthorised use of and access to the System will be
          detected. You shall immediately inform ExpressOne in the event that:
        </p>
        <p>
          10.5.1 You have reason to believe that your Credentials are or may be known to any person
          not authorised to know the same and/or have been compromised; and/or
        </p>
        <p>
          10.5.2 You have reason to believe that unauthorised use of the Services has or may have
          occurred or could occur and a transaction may have been fraudulently initiated or
          compromised.
        </p>
        <p>
          10.6 You shall at all times follow the security procedures notified to you by ExpressOne
          from time to time or such other procedures as may be applicable to the Services from time
          to time. You acknowledge that any failure on your part to follow the recommended security
          procedures may result in a breach of your Account’s confidentiality. In particular, you
          shall ensure that the Services are not used or Requests are not issued or the relevant
          functions are not performed by anyone other than a person authorised to do so.
        </p>
        <p>
          10.7 You shall not at any time operate or use the Services in any manner that may be
          prejudicial to ExpressOne.
        </p>

        <h2 className=' text-black font-bold text-xl'>11. DEFAULT ON LOAN</h2>
        <p>11.1 An event of default (Event of Default) occurs when:</p>
        <p>
          11.1.1 you fail to pay any sum payable under a Loan in accordance with a scheduled
          repayment.
        </p>
        <p>
          11.1.2 any representation/information, warranty or assurance made or given by the Borrower
          in connection with the application for a Loan or any information or documentation supplied
          by the Borrower is incorrect, inaccurate or misleading;
        </p>
        <p>
          11.1.3 the Borrower does anything which may prejudice ExpressOne’s rights under this
          Agreement;
        </p>
        <p>
          11.1.4 you use or attempt to use the App or the Services for an unauthorized purpose; or
        </p>
        <p>
          11.1.5 the Borrower is declared bankrupt, insolvent or unable to pay his debts as they
          fall due under the laws of his country.
        </p>
        <p>
          11.2 At any time after an Event of Default has occurred which is continuing, ExpressOne
          may, without prejudice to any other right or remedy granted to it under any law:
        </p>
        <p>11.2.1 terminate this Agreement in accordance with clause 12 below;</p>
        <p>
          11.2.2 declare that the Loan (and all accrued interest and all other amounts outstanding
          under this Agreement is immediately due and payable, whereupon they shall become
          immediately due and payable; and
        </p>
        <p>
          11.2.3 supply information concerning the Event of Default to Credit Reference Bureaus. A
          copy of any adverse information concerning you sent to a Credit Reference Bureau shall be
          made available to you upon written request.
        </p>
        <p>
          11.3 ExpressOne reserves the right to assign its right, title and interest under the
          Agreement to an external collections agency who will take all reasonable steps to collect
          the outstanding Loan (together with any interest and fees accrued), and in this regard,
          ExpressOne shall have the right to share with such collection agencies, any information
          about the Borrower which it deems relevant.
        </p>
        <p>
          11.4 ExpressOne also reserves the right to institute legal proceedings for the recovery of
          the Loan against you without any prior notice.
        </p>
        <p>
          11.5 The Borrower shall be responsible for all legal costs and expenses incurred by
          ExpressOne in connection with any enforcement action taken by ExpressOne for the
          collection and repayment of the Loan (together with any interest or fees due on the Loan).
        </p>
        <p>
          11.6 In the event that an Event of Default occurs, the Borrower grants to ExpressOne the
          right to notify the Borrower and any other person who, in ExpressOne’s opinion, may assist
          with the recovery of the outstanding loan amount. You agree that this notification may be
          done by any means of communication which ExpressOne deems appropriate.
        </p>
        <h2 className=' text-black font-bold text-xl'>12. VARIATION AND TERMINATION</h2>
        <p>
          12.1 ExpressOne may at any time, upon notice to you, terminate or vary its business
          relationship with you and close your Account and in particular but without prejudice to
          the generality of the foregoing, ExpressOne may cancel credits which it has granted and
          require the repayment of outstanding debts resulting from such credits within such time as
          ExpressOne may determine.{' '}
        </p>
        <p>
          12.2 Without prejudice to ExpressOne’s rights under clause 12.1, ExpressOne may at its
          sole discretion suspend or close your Account:
        </p>
        <p>
          12.2.1 you use the Account for unauthorised purposes or where ExpressOne detects any
          abuse/misuse, breach of content, fraud or attempted fraud relating to your use of the
          Services;
        </p>
        <p>
          12.2.2 if your Account or agreement with a Mobile Network Operator is terminated for
          whatever reason;
        </p>
        <p>
          12.2.3 if ExpressOne is required or requested to comply with an order or instruction of or
          a recommendation from the government, court, regulator or other competent authority;
        </p>
        <p>
          12.2.4 if ExpressOne reasonably suspects or believes that you are in breach of these Terms
          and Conditions (including non-payment of any Loan amount due from you where applicable)
          which you fail to remedy (if remediable) within 14 days after the service of notice by
          email, SMS or other electronic means requiring you to do so;
        </p>
        <p>
          12.2.5 where such a suspension or variation is necessary as a consequence of technical
          problems or for reasons of safety; to facilitate update or upgrade the contents or
          functionality of the Services from time to time; or where your Account becomes inactive or
          dormant;
        </p>
        <p>
          12.2.6 if ExpressOne decides to suspend or cease the provision of the Services for
          commercial reasons or for any other reason as it may determine in its absolute discretion;
          or
        </p>
        <p>
          12.2.7 if you breach any of the Licence Restrictions or the Acceptable Use Restrictions.
        </p>
        <p>
          12.3 If your Account has a credit balance at any time as a result of overpayment of your
          Loan, you may issue a Request to ExpressOne for payment of such credit balance and
          ExpressOne will return any such balance to you, less any applicable fees, provided that
          such amount falls above the minimum transfer amounts specified by the relevant Payment
          System Provider.
        </p>
        <p>
          12.4 Termination shall however not affect any accrued rights and liabilities of either
          party.
        </p>
        <p>
          12.5 If ExpressOne receives notice of your demise, ExpressOne will not be obliged to allow
          any operation or withdrawal from your Account by any person except upon production of
          administration letters from a competent authority or confirmed grant of letters of
          administration or confirmed grant of probate by your legal representatives duly appointed
          by a court of competent jurisdiction.
        </p>
        <h2 className=' text-black font-bold text-xl'>13. EXCLUSION OF LIABILITY</h2>
        <p>
          13.1 ExpressOne shall not be responsible for any loss suffered by you should the Services
          be interfered with or be unavailable by reason of the failure of any of your Equipment, or
          any other circumstances whatsoever not within ExpressOne’s control including , without
          limitation, Force Majeure or error, interruption, delay or non- availability of the
          System, terrorist or any enemy action, loss of power, adverse weather or atmospheric
          conditions, and failure of any public or private telecommunications system.
        </p>
        <p>
          13.2 You acknowledge that the App has not been developed to meet your individual
          requirements, and that it is therefore your responsibility to ensure that the facilities
          and functions of the App as described meet your requirements.
        </p>
        <p>
          13.3 We only provide the Services on the App for domestic, private use, and small and
          medium business financing needs, and we have no liability to you for any loss of profit,
          loss of business, business interruption, or loss of business opportunity as a result of
          your unauthorised use of the App.{' '}
        </p>
        <p>
          {' '}
          13.4 ExpressOne will not be liable for any losses or damage suffered by you as a result of
          or in connection with:
        </p>
        <p>
          13.4.1 any defect or fault in the App or any Service resulting from you having altered or
          modified the App;
        </p>
        <p>
          13.4.2 any defect or fault in the App resulting from you having used the App in breach of
          the terms of this Agreement;
        </p>
        <p>
          13.4.3 your breach of any of the Licence Restrictions or the Acceptable Use Restrictions;
        </p>
        <p>13.4.4 unavailability of sufficient funds in your Value Storage Account;</p>
        <p>
          13.4.5 failure, malfunction, interruption or unavailability of the System, your Equipment,
          the Network or the Electronic Payment System; the money in your Account being subject to
          legal process or other encumbrance restricting payments or transfers thereof; your failure
          to give proper or complete instructions for payments or transfers relating to your
          Account;
        </p>
        <p>
          13.4.6 any fraudulent or illegal use of the Services, the System and/or your Equipment; or
        </p>
        <p>
          13.4.7 your failure to comply with the Terms and Conditions and any document or
          information provided by ExpressOne concerning the use of the System and the Services.
        </p>
        <p>
          13.5 If for any reason other than a reason mentioned in clauses 13.1 to 13.4, the Services
          are interfered with or unavailable, ExpressOne’s sole liability under this Agreement in
          respect thereof shall be to re-establish the Services as soon as reasonably practicable.
        </p>
        <p>
          13.6 Save as provided in clause 13.5, ExpressOne shall not be liable to you for any
          interference with or unavailability of the Services, howsoever caused.
        </p>
        <p>
          13.7 Under no circumstances shall ExpressOne be liable to you for any loss of profit or
          anticipated savings or for any indirect or consequential loss or damage of whatever kind,
          howsoever caused, arising out of or in connection with the Services even where the
          possibility of such loss or damage is notified to ExpressOne.
        </p>
        <p>
          13.8 All warranties and obligations implied by law are hereby excluded to the fullest
          extent permitted by law.
        </p>
        <h2 className=' text-black font-bold text-xl'>14. INDEMNITY</h2>
        <p>
          14.1 In consideration of ExpressOne complying with your instructions or requests in
          relation to your Account, you undertake to indemnify ExpressOne and hold it harmless
          against any loss, charge, damage, expense, fee or claim which ExpressOne suffers or incurs
          or sustains thereby and you absolve ExpressOne from all liability for loss or damage which
          you may sustain from ExpressOne acting on your instructions or Requests or in accordance
          with these Terms and Conditions.
        </p>
        <p>14.2 The indemnity in clause 14.1 shall also cover the following:</p>
        <p>
          14.2.1 All demands, claims, actions, losses and damages of whatever nature which may be
          brought against ExpressOne or which it may suffer or incur arising from its acting or not
          acting on any Request or arising from the malfunction or failure or unavailability of any
          hardware, software, or equipment, the loss or destruction of any data, power failures,
          corruption of storage media, natural phenomena, riots, acts of vandalism, sabotage,
          terrorism, any other event beyond ExpressOne’s control, interruption or distortion of
          communication links or arising from reliance on any person or any incorrect, illegible,
          incomplete or inaccurate information or data contained in any Request received by
          ExpressOne.
        </p>
        <p>
          14.2.2 Any loss or damage that may arise from your use, misuse, abuse or possession of any
          third-party software, including without limitation, any operating system, browser software
          or any other software packages or programs.
        </p>
        <p>
          14.2.3 Any unauthorised access to your Account or any breach of security or any
          destruction or accessing of your data or any destruction or theft of or damage to any of
          your Equipment.
        </p>
        <p>
          14.2.4 Any loss or damage occasioned by the failure by you to adhere to these Terms and
          Conditions and/or by supplying of incorrect information or loss or damage occasioned by
          the failure or unavailability of third party facilities or systems or the inability of a
          third party to process a transaction or any loss which may be incurred by ExpressOne as a
          consequence of any breach of these Terms and Conditions.
        </p>
        <p>
          14.2.5 Any damages and costs payable to ExpressOne in respect of any claims against
          ExpressOne for recompense for loss where the particular circumstance is within your
          control.
        </p>
        <h2 className=' text-black font-bold text-xl'>15. COMMUNICATION BETWEEN US</h2>
        <p>
          15.1 If the Borrower wishes to contact ExpressOne in writing, or if any condition in these
          Terms and Conditions requires the Borrower to give notice to ExpressOne, you can send this
          to us by e-mail to{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>{' '}
          or to such e-mail address that may be communicated to you from time to time. We will
          confirm receipt of this by contacting you in writing by e-mail.{' '}
        </p>
        <p>
          15.2 If we have to contact you or give you notice in writing, we will do so by posting
          such notice on our website; or by e-mail or sms to the mobile phone number or e-mail
          address you provide to us in your request for the App.
        </p>
        <p> </p>
        <h2 className=' text-black font-bold text-xl'>16. GENERAL</h2>
        <p>16.1 Remedies Cumulative</p>
        <p>
          No failure on the part of any party to exercise, or delay on its part in exercising, any
          right, power or remedy provided by this Agreement or by law shall operate as a waiver
          thereof, nor shall any single or partial exercise of any such right, power or remedy
          preclude any further or other exercise of that, or any other, right, power or remedy.
        </p>
        <p>16.2 No waiver</p>
        <p>
          No failure by ExpressOne to exercise, and no delay in exercising, any right or remedy in
          respect of any provision of this Agreement shall operate as a waiver of such right or
          remedy.
        </p>
        <p>16.3 Effect of invalidity</p>
        <p>
          If any provision or part of a provision of this Agreement shall be, or be found by any
          court of competent jurisdiction to be, invalid or unenforceable, such invalidity or
          unenforceability shall not affect the other provisions or parts of such provisions of this
          Agreement, all of which shall remain in full force and effect.
        </p>
        <h2 className=' text-black font-bold text-xl'>17. ENTIRE AGREEMENT</h2>
        <p>
          17.1 These Terms and Conditions and our Privacy Policy constitute the entire agreement
          between you and us and supersede and extinguish all previous agreements, promises,
          assurances, warranties, representations and understandings between us, whether written or
          oral, relating to its subject matter.
        </p>
        <p>
          17.2 You acknowledge that in entering into this Agreement you do not rely on any
          statement, representation, assurance or warranty (whether made innocently or negligently)
          that is not set out in these Terms and Conditions or our Privacy Policy.
        </p>
        <p>
          17.3 You agree with us that neither of us shall have any claim for innocent or negligent
          misrepresentation or negligent misstatement based on any statement in this Agreement.
        </p>
        <h2 className=' text-black font-bold text-xl'>18. DISPUTE RESOLUTION</h2>
        <p> 18.1 Disputes</p>
        <p>
          The Parties shall use their good faith efforts to resolve any dispute, controversy or
          claim of any nature whatsoever arising out of or in relation to or in connection with this
          Agreement. To this end, the Parties in dispute shall each promptly appoint representatives
          of appropriate standing who shall meet and attempt to resolve any dispute between them. In
          the event that an amicable settlement has not been reached within thirty (30) days of the
          parties’ representatives meeting as aforesaid, the following provisions of this clause 18
          shall apply.
        </p>
        <p>18.2 Arbitration</p>
        <p>
          {' '}
          18.2.1 Any dispute, difference or question whatsoever and howsoever arising out of or in
          connection with this Agreement, save as specifically provided herein, shall be referred
          for final determination to a single arbitrator to be appointed by agreement between the
          parties hereto or in default of any such agreement within seven (7) days of the
          notification of any dispute by either party to the other then, upon application by either
          party, by the Chairman for the time being of the Institute of Arbitrators in your country
          (the Institute).
        </p>
        <p>
          18.2.2 Such arbitration shall take place in your country and shall be conducted in
          accordance with the applicable rules of arbitration of the Institute.
        </p>
        <p>
          18.2.3 To the extent permissible by law the determination of the arbitrator shall be final
          and binding upon the Parties and shall not be subject to any appeal.
        </p>
        <p>
          18.2.4 Nothing in this clause 18.2 shall restrict either Party’s freedom to commence legal
          proceedings of any nature for the purposes of seeking preliminary injunctive relief or
          interim or conservatory measures from any court of competent jurisdiction pending the
          final decision or award of any arbitrator.
        </p>
        <h2 className=' text-black font-bold text-xl'>19. GOVERNING LAW</h2>
        <p>
          This Agreement shall be governed by and construed in accordance with the laws of your
          country.
        </p>

        <h2 className=' text-black font-bold text-xl'>20. EXPRESSONE’S PRIVACY POLICY</h2>
        <p>
          20.1 We only use your personal information in accordance with our Privacy Policy. Please
          take the time to read our Privacy Policy, as it includes important terms which apply to
          you.
        </p>
        <p>
          20.2 Upon downloading the App and clicking the “Accept” or “Continue” or any other button
          which signifies your acceptance of these Terms and Conditions and an intention to use the
          App, you will be deemed to have accepted ExpressOne’s Privacy Policy, a copy of which is
          available on the App.
        </p>
        <h2 className=' text-black font-bold text-xl'>21. NIGERIA SPECIFIC PROVISIONS</h2>
        <p>
          These additional terms and conditions shall apply to borrowers or users of the App and
          Services who are resident in Nigeria (“Nigerian Borrower”).
        </p>
        <p>21.1 ExpressOne Limited Nigeria Entity</p>
        <p>
          The relevant associated ExpressOne entity in Nigeria is ExpressOne Limited, an entity duly
          incorporated as a private limited liability company under the Companies and Allied Matters
          Act Cap C20, LFN 2004. ExpressOne is duly authorised to provide short term unsecured loans
          to individuals via a mobile lending application.
        </p>
        <p>21.2 Governing Law</p>
        <p>
          The Terms and Conditions between ExpressOne Limited Nigeria and a Nigerian Borrower shall
          be governed by and construed in accordance with Nigerian Law.
        </p>
        <p>21.3 Additional Dispute Resolution Provisions</p>
        <p>
          Notwithstanding any provision to the contrary in this Agreement, ExpressOne Limited
          Nigeria shall have the right to institute proceedings for recovery of any outstanding
          amounts against a defaulting Nigerian Borrower in the High Court of Lagos State.
        </p>
      </div>
    </Element>
  )
}

export default Terms
