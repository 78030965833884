import React from 'react'
import { Element } from 'react-scroll'

const Loan = () => {
  return (
    <Element className='pt-24' id='loan' name='loan'>
      <h2 className='text-xl font-bold text-black'>Offer Letter</h2>

      <div className='mt-2 space-y-4 leading-relaxed '>
        <h2 className='font-bold text-black text-xl'>Advance and Repayment</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            Page agrees to advance the Loan and the Obligor agrees to take the loan subject to the
            terms and conditions as set out in the loan application flow and also herein in this
            offer letter terms and conditions.
          </li>
          <li>
            The Obligor agrees to repay the Loan (principal plus accrued interest) in accordance
            with the terms and conditions document.
          </li>
        </ol>
        <h2 className='font-bold text-black text-xl'>Interest</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            The repayment installments as calculated in the dedicated repayment schedule are done on
            the basis of payment at regular agreed intervals. If the due date for a repayment
            installment falls on a non-business day, then the repayment installment may be paid on
            the next succeeding business day. Additional interest may accrue in such cases.
          </li>
          <li>ExpressOne will calculate interest on the daily balance outstanding.</li>
          <li>
            Where the Obligor fails to pay ExpressOne a repayment installment by the due date,
            ExpressOne will charge default interest rate at 0.5% per month on the unpaid sum.
          </li>
          <li>
            ExpressOne will apply default interest rate from the date on which the Obligor was due
            to pay the sum.
          </li>

          <li className='font-bold'>The default interest rate</li>
          <ul className=' list-disc px-5'>
            <li>
              is charged by ExpressOne in addition to the normal interest rate which it charges the
              Obligor at the same time and in the same manner as that normal interest.
            </li>
            <li>
              accrues before and after any court judgment, ExpressOne obtains against the Obligor.
            </li>
            <li>
              is liquidated damages to compensate ExpressOne for the additional risk and other
              administrative costs and expenses associated with the Obligor’s failure to pay the
              outstanding sum.
            </li>
          </ul>
        </ol>
        <h2 className='font-bold text-xl text-black'>Representations and Warranties</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            He/she has the right to accept this facility and has taken all necessary actions to
            authorize same upon the terms and conditions herein.
          </li>
          <li>
            He/she is not in default under any obligation in respect of any borrowed money that the
            acceptance of this facility will be or result in breach of or default under any
            provision of any other agreement to which the Obligor is a party.
          </li>
          <li>
            The information given to ExpressOne electronical via the ExpressOne app or email
            correspondence in the formal loan application process is true and accurate
          </li>
          <li>
            He/she will use the loan for the purpose(s) they told ExpressOne of in their application
            for the loan.
          </li>
        </ol>
        <h2 className='font-bold text-xl text-black'>Events of Default</h2>
        <p>
          The occurrence of any of the following events shall cause all outstanding amounts under
          this facility to become immediately due and payable:
        </p>
        <ol className=' list-decimal  px-5'>
          <li>
            The Obligor fails to make a repayment or payment of principal, interest or other amount
            in respect of the loan on the date it was due to be paid; or
          </li>
          <li>
            The Obligor breaches any of the terms and conditions of the Loan including any
            representation or confirmation given by the Obligor in this loan agreement
          </li>
          <li>Where a bankruptcy petition is filed against the Obligor.</li>
          <li>
            Where the Obligor is unable to pay any other party within the meaning of Section 1 of
            the Bankruptcy Act (Cap 30) Laws of the Federation of Nigeria.
          </li>
          <li>
            Where a situation arises which in the opinion of ExpressOne makes it inappropriate for
            ExpressOne to continue to extend the facility to the Obligor.
          </li>
          <li>
            Where the Obligor defaults in the performance or observance of any other term, condition
            or covenant herein and such breach or default shall continue unremedied after seven
            days’ notice shall have been given to the Obligor.
          </li>
        </ol>
        <h2 className=' text-black font-bold text-xl'>Costs and Expenses</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            The Obligor hereby agrees to pay all administrative costs, charges and expenses
            (including legal fees) reasonably incurred by ExpressOne in connection with the
            processing of this loan request.
          </li>
          <li>
            The Obligor hereby agrees to pay all costs and expenses (including legal fees) incurred
            by ExpressOne in connection with any proceedings instituted by or against ExpressOne as
            a consequence of entering into this loan agreement.
          </li>
        </ol>
        <h2 className=' text-black font-bold text-xl'>Assignment and Disclosure of Information</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            The Obligor consents irrevocably to any future transfer and assignment, however arising
            of the loan, whether as part of a loan transfer scheme or otherwise.
          </li>
          <li>
            The Obligor authorizes ExpressOne to disclose any information or documentation relating
            to the loan to third parties including credit reference agencies, collection agencies,
            as well as the Obligor’s employers (where the Obligor is in salaried employment) in the
            event that the loan has ceased to be serviced by the Obligor.
          </li>
        </ol>
        <h2 className=' text-black font-bold text-xl'>Variation of Conditions</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            ExpressOne reserves the right at all times to vary the terms and conditions of the loan
            agreement. Any such variation will become effective upon notice to the Obligor by any
            means ExpressOne considers reasonable in the circumstance.
          </li>
        </ol>
        <h2 className=' text-black font-bold text-xl'>Set-Off </h2>
        <ol className=' list-decimal  px-5'>
          <li>
            ExpressOne may at any time with or without notice (a) combine or consolidate some or all
            of the Obligor’s accounts with it without any liability to ExpressOne and (b) set off
            and transfer any sum standing to the credit of any such account in full or partial
            payment of any amount the Obligor owes to the Company.
          </li>
          <li>
            This clause is in addition to and does not amend or qualify any other present or future
            right of ExpressOne to combine or set off any of the Obligor’s accounts with it.
          </li>
        </ol>
        <h2 className=' text-black font-bold text-xl'>Governing Law</h2>
        <ol className=' list-decimal  px-5'>
          <li>
            This loan agreement is governed by Nigerian law and the courts of the Federal Republic
            of Nigeria have jurisdiction in any matter arising from it.
          </li>
          <ul className=' list-disc px-5'>
            <li>
              *This offer is subject to a satisfactory credit report and further verification
              checks.**
            </li>
          </ul>
        </ol>
        <h2 className=' text-black font-bold text-xl'>DISCLAIMER:</h2>
        <p>
          As required by law, ExpressOne is mandated to disclose the status of defaulting loans to
          relevant local or international Credit Bureaus, Credit Monitoring Agencies and Regulatory
          Bodies.
        </p>
      </div>
    </Element>
  )
}

export default Loan
