import React from 'react'
import { Element } from 'react-scroll'

export const Scope = () => {
  return (
    <Element name='privacy' className='pt-24' id='privacy'>
      <h2 className='text-xl font-bold text-black'>Privacy Policy</h2>

      <div className='mt-2 space-y-4 leading-relaxed '>
        <p>
          This privacy policy (Policy) together with our Terms of Use and Loan Account Agreement as
          set out and any additional terms of use applies to your use of:
        </p>
        <ol className='px-5 list-decimal'>
          <li>
            ExpressOne mobile application software (App) available on our site OR hosted on the
            Google Play Store (App Site), once you have downloaded or streamed a copy of the App
            onto your mobile telephone or handheld device (Device).
          </li>
          <li>
            Any of the services accessible through the App (Services) or those available on the App
            Site or other sites of ours or other third-party Sites (Service Sites).
          </li>
        </ol>
        <p>
          This policy sets out the basis on which any personal data we collect from you, or that you
          provide to us, will be processed by us.
        </p>
        <p>
          Please read the following carefully to understand our views and practices regarding your
          personal data and how we will treat it. By downloading the App, you confirm that you have
          read, understood and accept the terms of this Policy set out hereunder. You also consent
          to the collection, use, storage, processing and disclosure of your personal information in
          the manner set out in this Policy.
        </p>
        <h2 className='font-bold text-black text-xl'>A. Information we may collect from you</h2>
        <p>We may collect and process the following data about you.</p>
        <h2 className='font-bold text-black'>
          i. Information you give us about you (Submitted information):
        </h2>
        <p>This may include information:</p>
        <ul className=' list-disc  px-5'>
          <li>provided by filling in forms when visiting our sites or on our App;</li>
          <li>provided by corresponding with us (for example, by e-mail or chat);</li>
          <li>
            provided by downloading or signing up on the App, subscribing to any of our Services
            (such as applying for a loan), searching for an app or Service, sharing data via the
            App's social media functions, entering a competition, promotion or survey, and reporting
            a problem with the App, our Services, our App Site or any of Our Service Sites.
          </li>
        </ul>
        <p>
          This information may include your name, address, e-mail address and phone number, the
          Device's phone number, age, username, password and other registration information,
          financial and credit information (including your mobile money account details, bank
          account details, and bank verification number, where applicable), personal description and
          photograph.
        </p>
        <h2 className='font-bold text-black'>
          ii. Information we collect about you and your device.
        </h2>
        <p>
          Each time you visit one of our Sites or use one of our Apps we may automatically collect
          the following information:
        </p>
        <ul className=' list-disc px-5'>
          <li className=''>
            technical information, including the type of mobile device you use, unique device
            identifiers (for example, your Device's IMEI or serial number), information about the
            SIM card used by the Device, mobile network information, your Device’s operating system,
            the type of browser you use, or your Device’s location and time zone setting (Device
            Information);
          </li>
          <li>
            information stored on your Device, including contact lists, call logs, SMS logs, social
            media accounts, other financial applications, photos, videos or other digital content
            (Content Information);
          </li>
          <li>
            data from your use of any other third-party application on the Device or the Service
            Sites; and
          </li>
          <li>
            details of your use of any of our Apps or your visits to any of Our Service Sites;
            including, but not limited to, traffic data, location data, weblogs and other
            communication data (Log Information).
          </li>
        </ul>
        <h2 className='font-bold text-black'>iii. Location information. </h2>
        <p>
          We also use GPS technology OR other location services to determine your current location.
          You can withdraw your consent to our collection, processing or use of this information at
          any time by logging out and uninstalling the App from your Device.
        </p>
        <h2 className='font-bold text-black'>
          iv. Information we receive from other sources (Third Party Information).{' '}
        </h2>
        <p>
          Due to the nature of the Services which we provide, we are required to work with a number
          of third parties (including credit reference agencies and mobile network providers) and we
          may receive information about you from them.
        </p>
        <h2 className='font-bold text-black'>v. Unique application numbers. </h2>
        <p>
          When you install or uninstall a Service containing a unique application number or when
          such a Service searches for automatic updates, that number and information about your
          installation, for example, the type of operating system, may be sent to us.
        </p>
        <h2 className='text-xl text-black font-bold'>B. Tracking and cookies</h2>
        <p>
          We may use mobile tracking technologies and/or website cookies to distinguish you from
          other users of the App, App Site, or Service Sites. This helps us to provide you with a
          good experience when you use the App or browse any of the Service Sites and also allows us
          to improve the App and Our Service Sites.
        </p>
        <h2 className='text-xl text-black font-bold'>C. Uses made of the information</h2>
        <p>
          We may associate any category of information with any other category of information and
          will treat the combined information as personal data in accordance with this Policy for as
          long as it is combined.
        </p>
        <p>
          Information collected by us shall be used for the purpose of determining whether or not to
          provide a loan to the customer, the amount of such loan and the terms and conditions
          applicable to such loan.
        </p>
        <p>
          Save in compliance with an order of the Court, Arbitral Panel, Tribunal, Regulatory
          Directive or Order or any other legal or regulatory obligation, we do not disclose
          information about identifiable individuals to other parties, but we may provide them with
          anonymous aggregate information about our users (for example, we may inform them that 500
          men aged under 30 have applied for a loan on any given day).
        </p>
        <h2 className='text-xl text-black font-bold'>D. Disclosure of your information</h2>
        <p>
          We may disclose some or all of the data we collect from you when you download or use the
          App to credit reference bureaus.
        </p>
        <p>
          We may disclose your personal information to any member of our group, which means our
          subsidiaries, affiliates, our holding company and its subsidiaries.
        </p>
        <p>We may disclose your personal information to third parties:</p>
        <ul className=' list-disc px-5'>
          <li>
            in the event that we sell or buy any business or assets, in which case we may disclose
            your personal data to the prospective seller or buyer of such business or assets;
          </li>
          <li>
            if ExpressOne or substantially all of its assets are acquired by a third party, in which
            case personal data held by it about its customers will be one of the transferred assets;
          </li>
          <li>
            if we are under a duty to disclose or share your personal data in order to comply with
            any legal or regulatory obligation or request; and/or
          </li>
          <li>
            in order to: enforce our Terms and Conditions and other agreements or to investigate
            potential breaches; report defaulters to any credit bureau; or for the purpose of
            publishing statistics relating to the use of the App, in which case all information will
            be aggregated and made anonymous.
          </li>
        </ul>
        <h2 className='text-xl text-black font-bold'>E. Where We store your personal data</h2>
        <p>
          The data that we collect from you may be transferred to, and stored at, a destination
          outside your country of origin or residence (as applicable). It may also be processed by
          staff operating outside your country of origin or residence (as applicable), who work for
          us or for one of our suppliers. These staff members may be engaged in the fulfillment of
          your requests on the Service Sites. By submitting your personal data, you agree to the
          collection, transfer, storing or processing of your personal data in the manner set out
          above. We will take all steps reasonably necessary to ensure that your data is treated,
          stored and processed securely and in accordance with this privacy Policy.
        </p>
        <p>
          Where we have given you (or where you have chosen) a password that enables you to access
          certain parts of Our Service Sites, you are responsible for keeping this password
          confidential. We ask you not to share a password with anyone. Unfortunately, the
          transmission of information via the internet is not completely secure. Although we will do
          our best to protect your personal data, we cannot guarantee the security of your data
          transmitted to Our Service Sites; any transmission is at your own risk. Once we have
          received your information, we will use strict procedures and security features to try to
          prevent unauthorised access.
        </p>
        <p> </p>
        <h2 className='text-xl text-black font-bold'>F. Your rights</h2>
        <p>
          We will use your data for the purposes of compiling statistics relating to our user base
          or loan portfolio and may disclose such information to any third party for such purposes,
          provided that such information will always be anonymous.
        </p>
        <p>
          Should we wish to use your information for marketing purposes, we will inform you prior to
          such use. You shall be entitled to prevent such usage by informing us, within 10 days of
          being informed of the proposed use, that you do not wish to disclose such information. You
          can also exercise the right at any time by contacting us at{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>
          .
        </p>
        <h2 className='text-xl text-black font-bold'>G. Changes to privacy policy</h2>
        <p>
          Any changes we may make to this Policy in the future will be posted on this page and,
          where appropriate, notified to you when you next start the App or log onto one of the
          Service Sites. The new terms may be displayed on-screen and you may be required to read
          and accept them to continue your use of the App or the Services. In any event, by
          continuing to use the App or any Services after the posting of any changes, you confirm
          your continuing acceptance of this Policy together with such changes, and your consent to
          the terms set out therein.
        </p>
        <h2 className='text-xl text-black font-bold'>Use of Data</h2>
        <p>The Digital Banking Application uses the collected data for various purposes:</p>

        <ul className=' list-disc px-5'>
          <li>
            To comply with applicable laws, regulations, legal processes or government request
            including but not limited to Know Your Customer Principles, Customer Due Diligence,
            Anti-Money Laundering and Anti-Terrorism Financing
          </li>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to the Service</li>
          <li>
            To allow you to participate in interactive features of our Service when you choose to do
            so
          </li>
          <li>To provide customer care and support</li>
          <li>To provide analysis or valuable information so that we can improve the Service</li>
          <li>To monitor the usage of the Service</li>
          <li>
            To use your Personal Data for operational, marketing and other promotional purposes.
          </li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
        <h2 className='text-xl text-black font-bold'>Transfer of Data</h2>
        <p>
          Your information, including Personal Data, may be transferred to and maintained on
          computers located outside of your town, city, local government area, state, country or
          other governmental jurisdiction where the data protection laws may differ from those from
          your jurisdiction. If you are located outside Nigeria and choose to provide information to
          us, please note that we transfer the data, including Personal Data, to Nigeria and process
          it there. Your consent to this Privacy Policy followed by your submission of such
          information represents your agreement to that transfer. We will take all steps reasonably
          necessary to ensure that your data is treated securely and in accordance with this Privacy
          Policy and no transfer in your Personal Data will take place in an organization or a
          country unless there are adequate controls in place including the security of your data
          and other personal information.
        </p>
        <p>Disclosure of Data Legal Requirements</p>
        <p>
          We may disclose your Personal Data in the good faith belief that such action is necessary
          to:
        </p>
        <ul className=' list-disc px-5'>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend our rights or property</li>
          <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>Protect the personal safety of users of the Service or the public</li>
          <li>Protect against legal liability</li>
        </ul>
        <h2 className='text-xl text-black font-bold'>Security of Data</h2>
        <p>
          The security of your data is important to us but remember that no method of transmission
          over the Internet, or method of electronic storage is 100% secure. While we strive to use
          Global best practice commercially acceptable means to protect your Personal Data, we
          cannot guarantee its absolute security.
        </p>
        <p> </p>
        <p>
          However, it is important to note that your password is the key to your account. Please use
          unique numbers, letters and special characters, and do not share your password with
          anyone. If you do share your password with others, you will be responsible for all actions
          taken in the name of your account and the consequences. If you lose control of your
          password, you may lose substantial control over your personal information and other
          information submitted to us. You could also be subject to legally binding actions taken on
          your behalf. Therefore, if your password has been compromised for any reason or if you
          have grounds to believe that your password has been compromised, you should immediately
          contact us and change your password. You are reminded to log off from your account and
          close the browser when finished while using a shared computer.
        </p>
        <h2 className='text-xl text-black font-bold'>Service Providers</h2>
        <p>
          We may employ third party companies and individuals to facilitate our Service ("Service
          Providers"), to provide the Service on our behalf, to perform Service-related services or
          to assist us in analyzing how our Service is used. These third parties have access to your
          Personal Data only to perform these tasks on our behalf and are obligated not to disclose
          or use it for any other purpose.
        </p>
        <h2 className='text-xl text-black font-bold'>Links to Other Sites</h2>
        <p>Our Service does not address anyone under the age of 18 ("Children").</p>
        <p>
          {' '}
          We do not knowingly collect personally identifiable information from anyone under the age
          of 18. If you are a parent or guardian and you are aware that your child or children have
          provided us with personal data, please contact us at{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>
          . If we become aware that we have collected personal data from children without
          verification of parental consent, we shall take steps to remove that information from our
          servers.
        </p>
        <h2 className='text-xl text-black font-bold'>Your Data Privacy Rights</h2>
        <ul className=' list-disc px-5'>
          <li>
            Right to Rectification:
            <br />
            Users can modify or change their name, email, password, and mobile login PIN via their
            profile.
          </li>
          <li>Right of Access, Erasure, and Restrict Processing:</li>
          <li>
            Users can request access or erasure of their personal information, as well as request
            restriction on further processing of their personal information by contacting us at{' '}
            <a className='text-primary' href='mailto:support@expressoneng.com'>
              support@expressoneng.com
            </a>
            . Please allow up to 30 days for requests to be processed. We reserve the right to
            charge a reasonable fee to process excessive or repeated requests.
          </li>
          <li>Right to Withdraw Consent:</li>
          <li>
            Users can stop receiving our promotional emails by following the unsubscribe
            instructions included in every email we send, or by adjusting your Marketing Preferences
            in your profile. Users also have choices with respect to cookies, as described above. By
            modifying your browser preferences, you have the choice to accept all cookies, to be
            notified when a cookie is set, or to reject all cookies. If you choose to reject all
            cookies some parts of our Service may not work properly in your case.
          </li>
          <li>Right to lodge a complaint with a supervisory authority:</li>
          <li>
            Should you feel your data privacy rights are not being adequately protected by us, you
            have the right to lodge a formal complaint with the appropriate supervisory authority.
          </li>
        </ul>
        <h2 className='text-xl text-black font-bold'>Changes to this Privacy</h2>
        <p>
          Policy We may update our Privacy Policy from time to time. We will notify you of any
          changes by posting the new Privacy Policy on this page. We will let you know via email
          and/or a prominent notice on our Service, prior to the change becoming effective and
          update the "effective date" at the top of this Privacy Policy. You are advised to review
          this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
          effective when they are posted on this page.
        </p>
        <p>
          Contact us if you have questions regarding your data privacy rights or would like to
          submit a related data privacy right request, please email us at{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>
          . Please allow up to 30 days for requests to be processed.
        </p>
        <p>
          Contact us by sending an e-mail to{' '}
          <a className='text-primary' href='mailto:support@expressoneng.com'>
            support@expressoneng.com
          </a>{' '}
          if you have any questions about this Privacy Policy.
        </p>
      </div>
    </Element>
  )
}
