import React from 'react'
import { Menu } from '../components/Menu'
import License from '../components/privacy/License'
import Loan from '../components/privacy/Loan'
import { Scope } from '../components/privacy/Scope'
import Terms from '../components/privacy/Terms'

const Privacy = () => {
  return (
    <div
      style={{ width: '90%' }}
      className='max-w-screen-xl mt-4 text-sm md:text-base text-darkGray mx-auto '
    >
      <div className='flex relative'>
        <Menu />
        <div className='flex-1 space-y-16 pb-32'>
          <Scope />
          <Terms />
          <License />
          <Loan />
        </div>
      </div>
    </div>
  )
}

export default Privacy
